import React, { Component } from 'react';
import { connect } from 'react-redux';
import { last } from 'lodash';
import Helpers from '../../Helpers';
import ThreadMainMessage from './ThreadMainMessage';
import MessageThread from './MessageThread';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import { enableRightDrawerMessage } from '../../Actions/rightDrawerMessageActions';
import {
  addImageInComposer,
  clearMessage,
  eraseEditorContent,
  getMessage,
  handleDialogStatus,
  initForwardedMessage,
  initReplyMessage,
  initResendUnopenedMessage,
  sendDirectMessage,
  sendThreadMessage,
  setMessage,
  setReplyTo,
  updateEditorContent,
  updateMessageSettings,
} from '../../Actions/messageActions';
import { displayGallery } from '../../Actions/imageGalleryActions';
import {
  archiveMessages,
  clearSelectedMessage,
  getStatisticsMessages,
  updateLastMessageDateValue,
} from '../../Actions/messagesActions';

import { navigationControllerRedirect } from '../../Actions/navigationControllerActions';

import { updateComposerState } from '../../Actions/composeActions';

import { clearThread, getThread, setMainThreadMessage, setSelectedThread } from '../../Actions/threadActions';

import snackBarStatus from '../../Actions/snackbarActions';
import TemplateSettings from '../TemplateSettings';
import ReplyThread from '../../Components/ReplyThread';
import MessageWrapper from '../../Helpers/Entity/Message/Wrapper/MessageWrapper';
import { closeCustomModal, openCustomModal, updateCustomModal } from '../../Actions/customModalActions';
import { MESSAGE_COMPOSER_TYPE, MESSAGE_DESIGNER_TYPE, MSG_STATUSES } from '../../Config/Constants';
import SentMessagePostview from './../MessagePostView/SentMessagePostview';

import { getDateInTimezone } from '../../Helpers/date';
import MessageSelectedHeader from '../../Components/MessageSelectedHeader';
import getMemberInfo from '../../Helpers/getMemberInfo';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withRouter } from 'react-router-dom';
import ScheduledMessagePreview from '../../Components/ScheduledMessagePreview';
import LoaderOverlay from '../../Components/LoaderOverlay';
import trans from '../../Translations/translation.service';
import { getGroupsFromDictionary } from '../../Helpers/Groups';
import MessageModalManager, { MESSAGE_MODAL_MANAGER_VIEWS } from '../MessageModalManager';
import GalleryOptions from '../GalleryOptions';
import MessageEditor from '../../Components/MessageEditor';
import './index.sass';
import StatisticsReport from '../../Components/StatisticsReport';

class MessageSelectedContainer extends Component {
  state = {
    firstRun: true,
    activeStatistics: false,
    sendConfirmDialogOpen: false,
    confirmationMessage: '',
    isLoading: true,
  };

  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.updateComposerState({ selectedMessage: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.messageId !== prevProps.messageId) {
      this.loadMessage(this.props.messageId)
    }
  }

  loadMessage(messageId) {
    this.props.clearMessage();
    this.props.clearSelectedMessage();
    this.props.clearThread();

    if (!messageId) return;

    try {

      this.props.getMessage(messageId).then(() => {
        const { selectedMessage } = this.props;

        if (!selectedMessage) {
          return this.props.navigationControllerRedirect('/messages');
        }

        this.clear();
        this.props.updateComposerState({ selectedMessage: true });

        const { organization } = this.props;
        this.props.setMainThreadMessage(selectedMessage);
        if (
          organization &&
          organization.public_id &&
          selectedMessage &&
          !selectedMessage.isArchived &&
          selectedMessage.publicId
        ) {
          this.props.getStatisticsMessages(organization.public_id, selectedMessage.publicId);
        }

        this.props.initReplyMessage();

        this.setState({ firstRun: false });
        this.loadThread();

        this.setConfirmationMessage();
      });

    } catch (err) {
      console.error(err);
      this.props.snackBarStatus({
        payload: {
          enable: true,
          type: 'error',
          title: trans('message.getCompleteDataFailed'),
        },
      });
    }
  }

  componentDidMount() {
    this.loadMessage(this.props.messageId);
  }

  setConfirmationMessage = () => {
    const { allMembersPublicId, groupsDictionaryList, selectedMessage } = this.props;
    const isDirect = selectedMessage.sentByMember && selectedMessage.recipientGroups.length === 0;
    const isAllMembers = selectedMessage.recipientGroups.includes(allMembersPublicId); // TODO: fix
    let confirmationMessage;
    if (isDirect) {
      const fullName = getMemberInfo(selectedMessage.sentByMember).displayedFullName;
      confirmationMessage = `ATTENTION: You are about to send a message to  <b>${fullName}</b>. Would you like to proceed?`;
    } else if (isAllMembers) {
      confirmationMessage = 'ATTENTION: You are about to send a message to all the members of the parish. Would you like to proceed?';
    } else {
      const groupsStr = getGroupsFromDictionary(selectedMessage.recipientGroups, groupsDictionaryList).map(group => group.group && group.group.name).join(', ');
      confirmationMessage = `ATTENTION: You are about to send a message to all 
      the members of the <b>${groupsStr}</b> group${selectedMessage.recipientGroups.length > 1 ? 's' : ''}. Would you like to proceed?`
    }
    this.setState({ confirmationMessage });
  };

  clear = () => {
    this.props.clearMessage();
    this.props.updateMessageSettings({
      includeRecipientName: false,
      previewText: '',
      emailForward: '',
      isScheduledMessage: false,
      timeZone: '',
      deliveryTime: '',
    });
  };

  handleSaveTemplate = event => {
    const { selectedMessage } = this.props;
    this.props.updateComposerState({ hidden: true });
    this.props.setMessage(selectedMessage);
    event.preventDefault();
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        element: (
          <TemplateSettings
            onSubmit={() => {
              this.props.clearMessage();
              this.props.updateComposerState({ hidden: false });
            }}
          />
        ),
        title: 'Save as template',
        secondTitle: '',
        cleaner: null,
        menuOptions: null,
      },
    });
  };

  handleStatisticsToggle = () => {
    this.setState({
      activeStatistics: !this.state.activeStatistics,
    });
  };

  handleReply = thread => {
    this.props.clearMessage();
    this.props.setSelectedThread(thread);
    this.props.initReplyMessage(true);

    if (window.innerWidth <= 768) {
      this.props.enableRightDrawerMessage({
        payload: {
          enable: true,
          isBackButton: true,
          backTitle: 'Messages',
          element: <ReplyThread/>,
          title: 'Messages',
          secondTitle: <div className="eva-custom-logo"/>,
        },
      });
    } else {
      this.props.enableRightDrawerMessage({
        payload: {
          enable: true,
          element: <ReplyThread/>,
          title: (
            <div className="header-text-reply-thread">
              <h3 className="title-drawer"> Reply Thread </h3>
              <p className="subtitle-drawer"> Informative message </p>
            </div>
          ),
          secondTitle: false,
          cleaner: null,
        },
      });
    }
  };

  handleResend = () => {
    const { selectedMessage, threadMainMessage } = this.props;

    this.props.clearMessage();

    this.props.initResendUnopenedMessage(selectedMessage);

    if (threadMainMessage.messageContent.type === MESSAGE_COMPOSER_TYPE) {
      this.props.openCustomModal({
        content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR} />
      });

    } else if (threadMainMessage.messageContent.type === MESSAGE_DESIGNER_TYPE) {
      this.props.enableRightDrawerMessage({ payload: { enable: false } });

      this.props.navigationControllerRedirect(`/messages/new?fromMsg=${selectedMessage.publicId}&resend=true`);
    }
  };

  handleForward = () => {
    const message = this.props.threadMainMessage;
    const { publicId, messageContent: { type } } = message;

    this.props.clearMessage();

    if (type === MESSAGE_COMPOSER_TYPE) {
      this.props.initForwardedMessage(message);
      this.props.openCustomModal({ content: <MessageModalManager view={MESSAGE_MODAL_MANAGER_VIEWS.EDITOR}/> });
    } else if (message.messageContent.type === MESSAGE_DESIGNER_TYPE) {
      this.props.enableRightDrawerMessage({ payload: { enable: false } });

      this.props.navigationControllerRedirect(`/messages/new?fromMsg=${publicId}`);
    }
  };

  handlePostView = () => {
    const { allMembersPublicId, threadMainMessage } = this.props;

    this.props.openCustomModal({
      content: <SentMessagePostview
        handleClose={this.props.closeCustomModal}
        draftId={threadMainMessage.publicId}
        classes={this.props.classes}
        message={threadMainMessage}
        isAllMemberGroupSelected={threadMainMessage.recipientGroups.includes(allMembersPublicId)}
      />,
      title: 'All Versions Sent'
    });
  };

  handleStatisticsModal = (publicId, type) => {
    this.props.openCustomModal({
      content: <StatisticsReport publicId={publicId} type={type}/>,
      title: 'Report'
    });
  }

  loadThread = () => {
    const { selectedMessage, organization } = this.props;
    this.props.getThread(
      selectedMessage.publicId,
      organization.public_id,
      null,
      selectedMessage.isArchived,
    );
  };

  handleSend = () => {
    const { messageContent: { editorContent, mainPicture } } = this.props;
    if (!(editorContent.text).trim() && !mainPicture) {
      this.props.snackBarStatus({
        payload: {
          title: 'You must write something to send',
          type: 'error',
          enable: true,
        },
      });
    } else {
      this.toggleConfirmDialog(true);
    }
  };

  handleSendConfirm = () => {
    this.sendReply();
    this.toggleConfirmDialog(false);
  };

  toggleConfirmDialog = (bool) => {
    this.setState({ sendConfirmDialogOpen: bool })
  };

  sendReply = () => {
    this.props.setReplyTo(this.props.selectedMessage.publicId); // To be sure (could be reset if compose window was opened on this page)
    (this.props.recipientGroups.length > 0 ? this.props.sendThreadMessage() : this.sendDirectMessage())
      .then(() => {
        this.loadThread();
        this.props.eraseEditorContent();
      })
  };

  sendDirectMessage = async () => {
    const group = this.props.selectedMessage.sendToMembers && this.props.selectedMessage.sendToMembers.length > 0
      ? this.props.selectedMessage.sendToMembers[0].group : null;

    return group ? this.props.sendDirectMessage(group.publicId) : Promise.resolve();
  };

  handleGalleryOpen = () => {
    this.props.displayGallery(true); // TODO: remove(?)
    this.props.openCustomModal({
      content: <GalleryOptions onImagePick={src => {
        this.props.addImageInComposer(src);
        this.props.closeCustomModal()
        this.props.displayGallery(false); // TODO: remove(?)
      }}/>,
      title: 'Gallery'
    });
  }

  renderScheduledMsg = message => {
    const { organization } = this.props;
    return (
      <ScheduledMessagePreview
        cancel
        organizationTimezone={organization.time_zone}
        message={message}
        onCancelClick={() => this.props.handleDialogStatus(true)}/>
    );
  };

  renderThreadsList() {
    const { threadMessages, organization } = this.props;
    return (
      <div className="messages-threads">
        {threadMessages &&
        Array.isArray(threadMessages) &&
        threadMessages.length > 0 &&
        threadMessages
          .map((threadMessage) => {
            const threadMessageWrapper = new MessageWrapper(threadMessage);
            const lastThread = (threadMessage.responseMessages).slice(-1).pop();
            const counterReply = threadMessageWrapper.getResponseMessagesCount();
            return (
              <MessageThread
                key={threadMessage.publicId}
                organizationTimezone={organization.time_zone}
                isAdmin={Helpers.isMessageFromAdmin(threadMessage)}
                message={threadMessage}
                replyThread={this.handleReply}
                threadReplyDate={(counterReply > 0 && lastThread) ? Helpers.getDate(lastThread.createdAt) : ''}
              />
            );
          })}
      </div>
    );
  }

  renderMainThreadMessage() {
    const { threadMainMessage, statisticsThreadMessages, organization } = this.props;
    const { activeStatistics } = this.state;
    const sentByMember = threadMainMessage.sentByMember;
    const isUncertainMessage = threadMainMessage.status === MSG_STATUSES.UNCERTAIN;
    const isDirect = threadMainMessage.sentByMember && (!threadMainMessage.groups || threadMainMessage.groups.length === 0)

    return <ThreadMainMessage
      isUncertainMessage={isUncertainMessage}
      isDirect={isDirect}
      userName={getMemberInfo(sentByMember).displayedFullName}
      onResend={this.handleResend}
      onForward={this.handleForward}
      onPreview={this.handlePostView}
      saveAsTemplate={this.handleSaveTemplate}
      onStatisticsToggle={this.handleStatisticsToggle}
      showButtons={!isUncertainMessage}
      activeStatistics={activeStatistics}
      statisticsThreadMessages={statisticsThreadMessages}
      messageHtml={threadMainMessage.messageContent.editorContent.html}
      messagePublicId={threadMainMessage.publicId}
      files={threadMainMessage.files}
      userImage={sentByMember.profilePictureUrl}
      onStatisticsModalCall={this.handleStatisticsModal}
      date={getDateInTimezone(threadMainMessage.sentAt, organization.time_zone, 'MM/DD/YY h:mm A')}
    />
  }

  renderDialog = () => {
    // TODO: unify
    return (
      <div>
        <Dialog
          open={this.state.sendConfirmDialogOpen}
          onClose={() => this.toggleConfirmDialog(false)}
          className="return-to-edit"
          style={{
            backgroundColor: 'transparent',
            borderRadius: '15px',
            zIndex: '1300000',
          }}
        >
          <DialogContent className="return-to-edit-modal-message">
            <div className="attention-Settings" dangerouslySetInnerHTML={{ __html: this.state.confirmationMessage }}/>
            <div className="modal-actions">
              <button onClick={this.handleSendConfirm} className="button">
                Yes
              </button>
              <button onClick={() => this.toggleConfirmDialog(false)} className="button">
                No
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  };

  render() {
    const {
      selectedMessage,
      isLoading,
      threadMainMessage,
      composerActiveState,
      isMessageLoading,
      isMessageSending,
      onArchive,
      onUnArchive,
      onDelete,
      groupsDictionaryList,
      messageContent: { editorContent }
    } = this.props;

    if (isMessageLoading || !selectedMessage || !selectedMessage.publicId) {
      return <LoaderOverlay/>;
    }

    const selectedMessageWrapper = new MessageWrapper(selectedMessage);
    let title = selectedMessage.subject;

    const selectedGroups = getGroupsFromDictionary(selectedMessage.recipientGroups, groupsDictionaryList);

    let groupShortName = selectedGroups.map(g => g.group.shortName).join(', ');
    const memberName = getMemberInfo(selectedMessage.sentByMember).displayedFullName;
    let groupNames = selectedMessage.recipientGroups
      ? selectedGroups.map(g => g.group.name).join(', ')
      : memberName;
    const isUncertainMessage = selectedMessageWrapper.getStatus() === MSG_STATUSES.UNCERTAIN;

    if (!title || isUncertainMessage) {
      title = selectedMessageWrapper.getSubject();
      groupShortName = memberName;
    }

    if (selectedMessageWrapper.isDirect()) {
      groupShortName = groupNames = selectedMessageWrapper.getDirectRecipient();
    }

    if (selectedMessage.isDirect) {
      groupNames = getMemberInfo(selectedMessage.sendToMembers[0].member).displayedFullName
    }

    if (isUncertainMessage) {
      groupNames = 'Unknown Group';
    }

    const composerPlaceholder = selectedMessageWrapper.hasGroup() ? `Reply to ${groupNames}` : 'Type Message Here';

    return selectedMessageWrapper.isScheduled() ? (
      this.renderScheduledMsg(selectedMessage)
    ) : (
      <div id="message-selected">
        <MessageSelectedHeader
          title={title} subtitle={groupNames} subtitleMobile={groupShortName}
          isArchived={selectedMessage.isArchived}
          onArchive={() => onArchive([selectedMessage.publicId])}
          onUnArchive={() => onUnArchive([selectedMessage.publicId])}
          onDelete={() => onDelete([selectedMessage.publicId])}
        />

        <div className="content-inner-wrapper">
          <div>
            {threadMainMessage ? this.renderMainThreadMessage() : <LoaderOverlay/>}
          </div>

          <div className="reply-wrapper">
            {isLoading || this.state.firstRun ? <LoaderOverlay/> : this.renderThreadsList()}
            {isMessageSending && <LoaderOverlay/>}
            {!isMessageSending && (
              <MessageEditor
                option="selected"
                placeholder={composerPlaceholder}
                nextButtonText="Send now"
                html={composerActiveState.selectedMessage ? editorContent.html : ''}
                contentLength={composerActiveState.selectedMessage ? editorContent.text.length : 0}
                onGalleryOpen={this.handleGalleryOpen}
                onNextButtonClick={this.handleSend}
                onHtmlSet={c => this.props.updateEditorContent(c, null, true)}
              />
            )}
          </div>
        </div>
        {this.renderDialog()}
      </div>
    );
  }
}

const mS = state => ({
  messageContent: state.messageReducer.messageContent,
  subject: state.messageReducer.subject,
  recipientGroups: state.messageReducer.recipientGroups,
  isMessageSending: state.messageReducer.isMessageSending,
  replyToMessage: state.messageReducer.replyToMessage,
  isMessageLoading: state.messageReducer.isMessageLoading,

  groupsDictionaryList: state.groupsDictionaryReducer.list,
  allMembersPublicId: state.groupsDictionaryReducer.allMembersPublicId,

  selectedMessage: state.messagesReducer.selectedMessage,
  statisticsThreadMessages: state.messagesReducer.statisticsThreadMessages,

  isLoading: state.threadReducer.isLoading,
  threadMessages: state.threadReducer.threadMessages,
  threadMainMessage: state.threadReducer.threadMainMessage,

  organization: state.organizationReducer.organization,

  groups: state.groupsReducer.groups,

  composerActiveState: state.composeReducer.composerActiveState,
});

const mD = {
  addImageInComposer,
  displayGallery,
  enableRightDrawer,
  enableRightDrawerMessage,
  getThread,
  initResendUnopenedMessage,
  sendThreadMessage,
  clearMessage,
  setSelectedThread,
  setReplyTo,
  archiveMessages,
  clearSelectedMessage,
  clearThread,
  updateMessageSettings,
  updateLastMessageDateValue,
  getStatisticsMessages,
  snackBarStatus,
  sendDirectMessage,
  openCustomModal,
  updateCustomModal,
  updateEditorContent,
  eraseEditorContent,
  initReplyMessage,
  initForwardedMessage,
  setMainThreadMessage,
  updateComposerState,
  setMessage,
  getMessage,
  navigationControllerRedirect,
  closeCustomModal,
  handleDialogStatus,
};
/* eslint-enable */

export default withRouter(connect(mS, mD)(MessageSelectedContainer));

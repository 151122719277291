import React from 'react';
import MessageThreadItem from '../../Components/MessageThreadItem';
import ThreadMainMessageButtons from '../../Components/ThreadMainMessageButtons';
import { MessageStatistics } from '../../Components/MessageStatistics';
import ThreadMainMessagePropType from '../../PropTypes/ThreadMainMessagePropType';
import AttachmentsPreview from '../../Components/AttachmentsPreview';
import './index.sass';

const ThreadMainMessage = ({
  saveAsTemplate,
  messageHtml,
  messagePublicId,
  userImage,
  onStatisticsModalCall,
  date,
  showButtons = true,
  onStatisticsToggle,
  activeStatistics,
  statisticsThreadMessages,
  onForward,
  onPreview,
  onResend,
  userName,
  isUncertainMessage,
  isDirect,
  files,
}) => {
  return (
    <div id="message-view">
      <MessageThreadItem
        rtl={!isUncertainMessage}
        highlighted={!userName}
        fullWidth
        userName={userName}
        messageHtml={messageHtml}
        userImage={userImage}
        date={date}
      />

      {files && files.length > 0 && (
        <AttachmentsPreview files={files} />
      )}

      {showButtons && (
        <ThreadMainMessageButtons
          isResendUnopened={!isDirect}
          isStatistics={activeStatistics}
          onForwardClick={onForward}
          onHistoryClick={onPreview}
          onResend={onResend}
          onTemplateClick={saveAsTemplate}
          onStatisticsClick={onStatisticsToggle}
        />
      )}
      {activeStatistics && statisticsThreadMessages && (
        <MessageStatistics
          publicId={messagePublicId}
          data={statisticsThreadMessages}
          onClose={onStatisticsToggle}
          onStatisticsModalCall={onStatisticsModalCall}
        />
      )}
    </div>
  )
};

export default ThreadMainMessage;

ThreadMainMessage.propTypes = ThreadMainMessagePropType

ThreadMainMessage.defaultProps = {
  userImage: null,
  isUncertainMessage: false,
  isDirect: false,
  files: [],
  showButtons: false,
  activeStatistics: false,
  statisticsThreadMessages: null,
  userName: null,
};
